<template>
  <div id="integration-browse">
    <div class="content">
      <Portal to="action-bar">
        <ItemActions
          item="Integration"
          group-by=""
          order=""
          :columns="columns"
          :filter-by="filterBy"
          criteria=""
          :active-view.sync="activeView"
          @search="searchRow"
        />
      </Portal>
      <DetailsGridButton
        :connect-apps="connectApps"
        @refresh="getConnection"
      ></DetailsGridButton>
    </div>
  </div>
</template>

<script>
import DetailsGridButton from "./components/DetailsGridButton.vue";
import { integration, connector } from "@/api/factory.js";
import { Portal } from "portal-vue";
import ItemActions from "@/components/common/display/item-actions/ItemActions.vue";
export default {
  name: "IntegrationBrowseAndTrash",

  components: {
    DetailsGridButton,
    Portal,
    ItemActions,
  },

  props: {
    mode: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      columns: [
        {
          id: this.$nano.id(),
          name: "name",
          label: "Name",
          type: "TITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        },
        {
          id: this.$nano.id(),
          name: "description",
          label: "Description",
          type: "SUBTITLE",
          isVisible: true,
          isSortable: true,
          isGroupable: false,
        },
      ],
      connectApps: [
        {
          name: "jira",
          description:
            "Powerful project management and issue-tracking app designed for teams to plan, track, and manage their work efficiently. It offers Freal-time collaboration tools to streamline project tracking and enhance productivity",
          icon: "mdi-jira",
          label: "Connect",
          color: "blue",
        },
        {
          name: "smtp",
          description:
            "Provide the correct SMTP settings to establish a connection with your email server and send the messages",
          icon: "mdi-email-plus",
          label: "Connect",
          color: "deep-orange",
        },
        {
          name: "API",
          description:
            "The process of connecting two or more applications or systems by using APIs (Application Programming Interfaces) to exchange data and perform actions",
          icon: "eva-layers-outline",
          label: "Connect",
          color: "blue",
        },
        {
          name: "Sql",
          description:
            "Streamlines data connectivity and management across various databases using SQL. It enables real-time data synchronization, query execution, and automated workflows for efficient data handling and analysis",
          icon: "mdi-database",
          label: "Connect",
          color: "gray",
        },
        {
          name: "oracle",
          description:
            "Ensures seamless integration and synchronization of data across Oracle databases and other systems. It automates data replication, updates, and consistency checks, enhancing data accuracy and accessibility",
          icon: "mdi-database",
          label: "Connect",
          color: "red",
        },
        {
          name: "slack",
          description:
            "Powerful messaging platform designed to bring your team together, no matter where you are. With Slack, you can streamline communication, enhance productivity, and simplify teamwork through organized channels, direct messaging, and powerful integrations",
          icon: "mdi-slack",
          label: "Connect",
          color: "orange",
        },
        {
          name: "teams",
          description:
            "Collaboration platform that enables seamless teamwork with organized channels, direct messaging, and real-time collaboration tools. Enhance productivity and communication with built-in integrations and cross-platform accessibility",
          icon: "mdi-microsoft-teams",
          label: "Connect",
          color: "blue",
        },
        {
          name: "hubspot",
          description:
            "A CRM platform that connects everything scaling companies need to deliver a best-in-class customer experience into one place",
          icon: "mdi-hubspot",
          label: "Coming Soon",
          color: "orange",
        },
        {
          name: "docusign",
          description:
            "A signature platforam thar allows people to put their digital signatures on documents, such as contracts and agreements, using its secure technology",
          icon: "mdi-download",
          label: "Coming Soon",
          color: "gray",
        },
        {
          name: "AWS",
          description:
            "Amazon Web Services (AWS) is the world's most comprehensive and broadly adopted cloud, offering over 200 fully featured services from data centers globally.",
          icon: "mdi-aws",
          label: "Coming Soon",
          color: "warning",
        },
        {
          name: "GCP",
          description:
            "An international set of guidelines that helps make sure that the results of a clinical trial are reliable and that the patients are protected.",
          icon: "mdi-google-cloud",
          label: "Coming Soon",
          color: "orange",
        },
        {
          name: "Azure",
          description:
            "Microsoft Azure, often referred to as Azureis a cloud computing platform run by Microsoft, which offers access, management, and development of application s and services through global data centers.",
          icon: "mdi-microsoft-azure",
          label: "Coming Soon",
          color: "blue",
        },

        {
          name: "SAP",
          description:
            "SAP stands for System Applications and Products in Data Processing. SAP is the market leader in ERP software and helps companies of all sizes. ",
          icon: "mdi-text-box-search",
          label: "Coming Soon",
          color: "blue",
        },
        {
          name: "Salesforce",
          description:
            "Salesforce is a cloud-based Customer Relationship Management (CRM) platform that enables businesses to manage customer data, sales operations, and marketing campaigns.",
          icon: "mdi-salesforce",
          label: "Coming Soon",
          color: "black",
        },
        {
          name: "OCI",
          description:
            "Overseas Citizenship of India (OCI) is a form of permanent residency available to people of Indian origin and their spouses which allows them to live and work in India indefinitely.",
          icon: "mdi-oci",
          label: "Coming Soon",
          color: "red",
        },
        {
          name: "Uipath",
          description:
            "UiPath is a global software company that makes robotic process automation (RPA) software. It was founded in Bucharest, Romania, by Daniel Dines and Marius Tîrcă. Its headquarters are in New York City.",
          icon: "mdi-unicode",
          label: "Coming Soon",
          color: "red",
        },
        {
          name: "Blue prism",
          description:
            "Blue Prism is the trading name of the Blue Prism Group plc, a British multinational software corporation that pioneered and makes enterprise robotic process automation (RPA) software that provides a digital workforce designed to automate complex, end-to-end operational activities. ",
          icon: "mdi-diamond-stone",
          label: "Coming Soon",
          color: "blue",
        },
        {
          name: "Laiye",
          description:
            "Sophisticated optical character recognition solution designed to convert printed or handwritten text from images and documents into editable digital formats. With support for multiple languages and advanced text extraction capabilities, it ensures high accuracy and efficiency in data processing",
          icon: "mdi-alpha-a-circle-outline",
          label: "Coming Soon",
          color: "warning",
        },
        {
          name: "Lazarus",
          description:
            "Leverages advanced optical character recognition technology to accurately extract and digitize text from scanned documents and images. It supports multiple languages and complex layouts, enabling efficient data entry and document processing",
          icon: "mdi-bulma",
          label: "Coming Soon",
          color: "pink",
        },
        {
          name: "Nanonets",
          description:
            "Provides powerful optical character recognition capabilities to extract and digitize text from images and documents with high accuracy. It supports multiple languages and custom model training, enabling tailored text recognition for various industries",
          icon: "mdi-webhook",
          label: "Coming Soon",
          color: "gray",
        },
        {
          name: "Mongo DB",
          description:
            "MongoDB’s document model is simple for developers to learn and use, while still providing all the capabilities needed to meet the most complex requirements at any scale.",
          icon: "mdi-leaf",
          label: "Coming Soon",
          color: "green",
        },
        {
          name: "S3bucket",
          description:
            "A bucket is a container for objects stored in Amazon S3. You can store any number of objects in a bucket and can have up to 100 buckets in your account.",
          icon: "mdi-bucket",
          label: "Coming Soon",
          color: "red",
        },
        {
          name: "Google Drive",
          description:
            "Cloud storage service that allows you to store, share, and collaborate on files from any device. It offers seamless integration with Google Workspace apps, enabling real-time collaboration and file management.",
          icon: "mdi-google-drive",
          label: "Coming Soon",
          color: "orange",
        },
        {
          name: "DropBox",
          description:
            "Cloud storage solution that lets you store, sync, and share files effortlessly across devices. With easy file access and sharing options, it integrates smoothly with various apps for efficient collaboration.",
          icon: "mdi-dropbox",
          label: "Coming Soon",
          color: "gray",
        },
        {
          name: "Onedrive",
          description:
            "OneDrive is the Microsoft cloud service that connects you to all your files. It lets you store and protect your files, share them with others, and get to them from anywhere on all your devices.",
          icon: "mdi-onedrive",
          label: "Coming Soon",
          color: "blue",
        },
        {
          name: "Google",
          description:
            "Allows for seamless integration between your on-premises Active Directory and Google Workspace. It automates user account synchronization, including creation, updates, and deletions, ensuring consistent access and identity management across both platforms",
          icon: "mdi-google",
          label: "Coming Soon",
          color: "orange",
        },
        {
          name: "Office 365",
          description:
            "Enables seamless synchronization between your on-premises Active Directory and Office 365, ensuring consistent user identity management. It automates user account creation, updates, and deletions, while maintaining security and compliance across your cloud and local environments.",
          icon: "mdi-microsoft-dynamics-365",
          label: "Coming Soon",
          color: "red",
        },
        {
          name: "Ssid",
          description:
            "Allows organizations to synchronize Wi-Fi network access with AD user credentials, ensuring that only authenticated users can connect to specific SSIDs",
          icon: "mdi-wifi",
          label: "Coming Soon",
          color: "black",
        },

        {
          name: "Twilio",
          description:
            "Twilio helps organizations and brands of all sizes create meaningful moments with users across the globe—from the simplest text messages to life-saving communications.",
          icon: "mdi-router",
          label: "Coming Soon",
          color: "red",
        },
        {
          name: "Whatsapp",
          description:
            "WhatsApp is a free cross-platform messaging service. It lets users of iPhone and Android smartphones and Mac and Windows PC call and exchange text, photo, audio and video messages with others across the globe for free, regardless of the recipient's device.",
          icon: "mdi-whatsapp",
          label: "Coming Soon",
          color: "green",
        },
        {
          name: "Google cloud messages",
          description:
            "Google Cloud Messaging (GCM) was a mobile notification service developed by Google that enables third-party application developers to send notification data or information from developer-run servers to applications that target the Google Android Operating System, as well as applications or extensions developed for the Google Chrome internet browser. It was available to developers free of charge.",
          icon: "mdi-cloud-check-outline",
          label: "Coming Soon",
          color: "blue",
        },
        {
          name: "Telegram",
          description:
            "A telegram is a message that is sent electronically and then printed and delivered to someone's home or office. In the past, telegrams were sent by telegraph.",
          icon: "mdi-telegram",
          label: "Coming Soon",
          color: "blue",
        },
        {
          name: "Rest",
          description:
            "A REST connector is a middleware component or interface that facilitates communication between different software systems, typically a client and a server, using the REST (Representational State Transfer) architecture",
          icon: "mdi-seat-flat",
          label: "Coming Soon",
          color: "black",
        },
        {
          name: "Soap",
          description:
            "A SOAP connector enables the seamless integration and interaction of applications, especially in environments where robust security and transactional reliability are essential",
          icon: "mdi-spray-bottle",
          label: "Coming Soon",
          color: "gray",
        },
      ],
      connectAppsData: [],
      filterBy: [],
      activeView: "GRID",
    };
  },

  created() {
    this.getConnection();
    this.connectAppsData = this.connectApps;
  },

  methods: {
    getConnection() {
      this.getAppConnection();
      this.getSMTPConnection();
      this.getSQLAPIOracleConnection();
    },

    searchRow(search) {
      if (search) {
        this.connectApps = this.connectAppsData.filter((row) => {
          for (let cell in row) {
            let index = this.columns.findIndex((column) => {
              return column.name === cell;
            });
            if (index >= 0 && row[cell]) {
              if (
                String(row[cell]).toLowerCase().includes(search.toLowerCase())
              ) {
                return row;
              }
            }
          }
        });
      } else {
        this.connectApps = this.connectAppsData;
      }
    },

    async getAppConnection() {
      const { error, payload } = await integration.getJiraConnection({
        filterBy: [],
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.connectApps = this.connectApps.map((item) => {
          let apps = payload.findIndex((row) => row.cloudName === item.name);
          if (apps > -1) {
            return {
              ...item,
              label: "Connected",
            };
          } else {
            return {
              ...item,
            };
          }
        });
      }
    },

    async getSMTPConnection() {
      const { error, payload } = await integration.getSMTPConnection({
        filterBy: [],
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.connectApps.find((item) => {
          if (item.name === "smtp") {
            item.label = "Connected";
          }
        });
      }
    },

    async getSQLAPIOracleConnection() {
      const { error, payload } = await connector.getConnection({
        filterBy: [],
        mode: this.mode,
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        // console.log(payload);

        let sql = payload.filter(
          (item) =>
            item.createdBy === this.$store.state.session.id &&
            item.connectorType === "SQL"
        );
        if (sql.length) {
          this.connectApps.find((item) => {
            if (item.name === "Sql") {
              item.label = "Connected";
            }
          });
        }

        let api = payload.filter(
          (item) =>
            item.createdBy === this.$store.state.session.id &&
            item.connectorType === "oauth2"
        );
        if (api.length) {
          this.connectApps.find((item) => {
            if (item.name === "API") {
              item.label = "Connected";
            }
          });
        }

        let oracle = payload.filter(
          (item) =>
            item.createdBy === this.$store.state.session.id &&
            item.connectorType === "ORACLE"
        );
        if (oracle.length) {
          this.connectApps.find((item) => {
            if (item.name === "oracle") {
              item.label = "Connected";
            }
          });
        }

        let slack = payload.filter(
          (item) =>
            item.createdBy === this.$store.state.session.id &&
            item.connectorType === "slack"
        );
        if (slack.length) {
          this.connectApps.find((item) => {
            if (item.name === "slack") {
              item.label = "Connected";
            }
          });
        }

        let teams = payload.filter(
          (item) =>
            item.createdBy === this.$store.state.session.id &&
            item.connectorType === "teams"
        );
        // console.log(teams);
        if (teams.length) {
          this.connectApps.find((item) => {
            if (item.name === "teams") {
              item.label = "Connected";
            }
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#integration-browse {
  .content {
    padding-top: 8px;
    min-height: calc(100vh - 248px);
  }
}
</style>
